.app-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: lightgray;
}
.app-span-txt {
  font-size: 18px;
}
