.ip-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ip-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 24rem;
  height: 20rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  margin-top: 10rem;
}
.ip-input-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 20%;
}
.ip-input {
  height: 50%;
}
.ip-button {
  margin-top: 5px;
  width: 90%;
  height: 10%;
  background-color: gray;
  color: #fff;
  border: none;
  font-weight: 600;
}
.tab-bar {
  display: flex;
  justify-content: space-around;
  background-color: #fff;
}

.tab {
  padding: 10px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.active {
  color: #000000;
  font-weight: 600;
}
.tab-content {
  background-color: lightgray;
}
.map-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.map-circel-container {
  display: flex;
  justify-content: space-between;
  /* height: 20rem; */
}
.map-circel-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fan-img {
  height: 80px;
  width: 80px;
  margin: 20px;
}
.office-fan-img {
  height: 60px;
  width: 60px;
  margin: 20px;
}
.clicked {
  animation: App-logo-spin infinite 1s linear;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circle {
  background: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.map-partecion {
  background-color: #000000;
  width: 90%;
  height: 10%;
}
.office-map-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.office-circle {
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 20px;
}
.office-circle-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.office-circle-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.combos-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.combos-title {
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
  margin: 1rem;
}
.combos-row {
  display: flex;
}
.combos-column {
  display: flex;
  flex-direction: column;
}
.combos-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 0.5px solid gray;
  width: 12rem;
  height: 12rem;
}
.combos-circle {
  background: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 20px;
  border: 1px solid #000000;
}
.light-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.light-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 1rem;
  height: 4rem;
  width: 12rem;
  text-align: center;
  border-radius: 10px;
}
.light-text {
  font-size: 18px;
}
.phone-combo-div {
  display: flex;
  flex-wrap: wrap;
}
.res-light-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 1rem;
  height: 4rem;
  width: 8rem;
  text-align: center;
  border-radius: 10px;
}
.res-light-text {
  font-size: 12px;
  text-align: center;
}
@media (max-width: 768px) {
  .combos-row {
    flex-wrap: wrap;
  }

  .combos-card {
    width: 30%;
    margin: 1rem;
  }

  .combos-container {
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .combos-card {
    width: 40%;
  }
}
